import React from "react";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useNavigate } from "react-router-dom";
import { upperCaseFirstLetter } from "../../../helper/TextHelper";
import {
  Avatar,
  Box,
  CardContent,
  Grid,
  Card,
  CardActions,
  Typography,
  Tooltip,
} from "@mui/material";
import { MuiButton } from "../../../components";
import IconWithText from "../../../components/IconWithText";
import { CASE_STUDY_PURCHASE_ROUTE } from "../../../helper/Constants";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupsIcon from "@mui/icons-material/Groups";
import TopicIcon from "@mui/icons-material/Topic";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";

export default function AssignmentCard({
  assignment,
  handleDelete,
  handleGroupList,
  handleDownload,
  handleGuideline,
}) {
  const navigate = useNavigate();

  const getStatusColor = (status) => {
    switch (status) {
      case "UNKNOWN":
        return "#ff9800"; // Orange '#4caf50'; // Green '#f44336'; // Red  '#2196f3'; // Blue
      default:
        return "#4caf50";
    }
  };

  function formatString(str) {
    // Split the string by underscores
    let words = str.split("_");

    // Capitalize the first letter of each word and make the rest lowercase
    for (let i = 0; i < words.length; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words with spaces
    return words.join(" ");
  }

  const goForPayment = (caseStudyId, assignment) => {
    navigate(CASE_STUDY_PURCHASE_ROUTE, {
      state: { caseStudyId, assignment },
    });
  };

  const renderActions = (paymentStatus) => {
    switch (paymentStatus) {
      case "PAID":
        return (
          <CardActions
            disableSpacing
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              {assignment.hasEvaluation && (
                <MuiButton
                  id="assignment-group-btn"
                  size="small"
                  text="Groups"
                  variant="text"
                  sx={{ ml: 1 }}
                  onClick={handleGroupList}
                />
              )}
            </div>
            <div style={{ marginRight: "16px" }}>
              <Tooltip title={"Download Case Study"}>
                <IconButton
                  size="large"
                  color="primary"
                  onClick={() => handleDownload(assignment.title)}
                >
                  <PictureAsPdfIcon id="assignment-group-download-case-study" />
                  <DownloadIcon
                    sx={{
                      fontSize: 20,
                      position: "absolute",
                      bottom: -1,
                      right: -2,
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* {assignment.hasEvaluation && (
                <Tooltip title={"Download Guideline"}>
                  <IconButton
                    size="large"
                    color="primary"
                    onClick={() => handleGuideline()}
                  >
                    <MenuBookIcon id="assignment-group-download-guideline" />
                    <DownloadIcon
                      sx={{
                        fontSize: 20,
                        position: "absolute",
                        bottom: -1,
                        right: -2,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )} */}
            </div>
          </CardActions>
        );
      case "UNKNOWN":
        return (
          <CardActions>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <MuiButton
                  id="assignment-unlock"
                  variant="text"
                  size="small"
                  text="UnLock"
                  onClick={() =>
                    goForPayment(assignment.caseStudyId, assignment)
                  }
                />
              </Box>
            </Grid>
          </CardActions>
        );

      default:
        return null;
    }
  };

  return (
    <Card
      elevation={5}
      sx={{
        m: 0.5,
        border: "1px solid",
        borderColor: getStatusColor(assignment.paymentStatus),
      }}
    >
      <CardHeader
        avatar={
          assignment.paymentStatus !== "UNKNOWN" ? (
            <Avatar sx={{ bgcolor: "#008000" }}>
              <GroupsIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: "#f44336" }}>
              <LockIcon />
            </Avatar>
          )
        }
        action={
          <IconButton color="secondary" onClick={handleDelete}>
            <DeleteOutlinedIcon />
          </IconButton>
        }
        title={upperCaseFirstLetter(assignment.title)}
        subheader={
          <Box>
            <Typography variant="body2" color="textSecondary" component="div">
              Due date: {assignment.dueDate}
            </Typography>
            <Typography variant="caption" color="warning">
              <AccessTimeIcon
                color="warning"
                fontSize="small"
                style={{ verticalAlign: "middle", marginRight: 4 }}
              />
              11:59 PM
            </Typography>
          </Box>
        }
      />
      <CardContent sx={{ m: 1 }}>
        <>
          <IconWithText
            text={upperCaseFirstLetter(assignment.caseStudyTitle)}
            sx={{ mb: 1 }}
          />
          <IconWithText
            icon={<MenuBookIcon />}
            text={upperCaseFirstLetter(assignment.course)}
          />
          <IconWithText
            icon={<CalendarMonthIcon />}
            text={formatString(assignment.semester)}
          />
          <IconWithText
            icon={<TopicIcon />}
            text={formatString(assignment.concept)}
          />
          <Grid container>
            {/* <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">
              {formatString(assignment.course)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">
              {formatString(assignment.caseStudyTitle)}
            </Typography>
          </Grid> */}
            {/* <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <MuiButton
                  variant="text"
                  size="small"
                  text="UnLOCK"
                  onClick={handleClickOpen}
                />
              </Box>
            </Grid> */}
          </Grid>
        </>
      </CardContent>
      {renderActions(assignment.paymentStatus)}
    </Card>
  );
}
