import React, { useState, useEffect } from "react";
import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as assignmentService from "../../services/AssignmentService";
import * as caseStudyService from "../../services/CasestudyService";
import AssignmentCard from "./components/AssignmentCard";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/mui/Notification";
import PageHeader from "../../components/PageHeader";
import { STUDENT_ASSIGNMENT_GROUPS_ROUTE } from "../../helper/Constants";
import SearchBar from "./SearchBar";
import IndividualAssignmentCard from "./components/IndividualAssignmentCard";
import { ResponsiveDialog, TourRide, TourStep } from "../../components";

export default function List() {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [disclaimerDialog, setDisclaimerDialog] = useState({
    isOpen: false,
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const disclaimer = (assignment) =>
    "This case study has been developed under the supervision of your Professor exclusively for educational purposes in connection with the " +
    assignment.major +
    " class, utilizing BizCaseLab's proprietary AI-powered tools. The use of company information, including company name and publicly available data, is not intended to serve as an endorsement or illustration of effective or ineffective management or business practices. The industry and company information presented in the Industry Description, Industry Trends and Market Dynamics Analysis, Company History, Company Competitive Advantage Analysis, Competitors Analysis, and Macro- and Micro-Environment Analysis sections has been derived from publicly available sources and either generated or paraphrased by AI. The information has not been reviewed or approved by the company. The problem statement included in this case study is an AI-generated construct. All names, characters, and scenarios presented are fictional and have no basis in the real world. The problem statement was developed exclusively to present a hypothetical business scenario intended to facilitate students understanding of business concepts. This case study is designed solely as a learning tool for " +
    assignment.course +
    " class and does not purport to reflect actual industry or company practices or conditions. The company mentioned in the case have not endorsed or approved the content. BizCaseLab makes no representations or warranties regarding the accuracy or completeness of the information provided. Any printing, sharing, or use of this material is strictly prohibited. All rights to this material are reserved by BizCaseLab.com.";

  useEffect(() => {
    assignmentService
      .getAll()
      .then((response) => {
        setAssignments(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .del(id)
      .then(() => {
        const newList = assignments.filter((item) => item.id !== id);
        setAssignments(newList);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a project. Please try again later.",
          type: "error",
        });
      });
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        onDelete(id);
      },
    });
  };

  const handleGroupList = (assignment, caseStudyId) => {
    navigate(STUDENT_ASSIGNMENT_GROUPS_ROUTE, {
      state: { assignment, caseStudyId },
    });
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const handleSelection = (assignmentId) => {
    const isInList = assignments.some(
      (existingAssignment) => existingAssignment.id === assignmentId
    );
    if (!isInList) {
      assignmentService
        .enroll(assignmentId)
        .then((response) => {
          onRefresh();
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        });
    } else {
      setNotify({
        isOpen: true,
        message: "You have already enrolled on this project",
        type: "warning",
      });
    }
  };

  const downloadPdf = (assignment, title) => {
    setDisclaimerDialog({
      isOpen: true,
      title: "Disclaimer",
      content: disclaimer(assignment),
      actions: [
        {
          label: "Disagree",
          onClick: () =>
            setDisclaimerDialog({ ...disclaimerDialog, isOpen: false }),
        },
        {
          label: "Agree",
          onClick: () => download(assignment.caseStudyId, title),
          autoFocus: true,
        },
      ],
    });
  };

  const download = (caseStudyId, title) => {
    setDisclaimerDialog({ ...disclaimerDialog, isOpen: false });
    caseStudyService
      .getPdf(caseStudyId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Case_study_" + title + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response && e.response.status === 404) {
          setNotify({
            isOpen: true,
            message: "Could not find a pdf. Please try again later.",
            type: "warning",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        }
      });
  };

  const downloadGuideline = (caseStudyId, assignmentId, title) => {
    caseStudyService
      .getGuideline(caseStudyId, assignmentId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Guideline_" + title + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response && e.response.status === 404) {
          setNotify({
            isOpen: true,
            message: "Could not find a guideline. Please try again later.",
            type: "warning",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        }
      });
  };

  const tourSteps = [
    {
      target: "#search-assignment",
      content: (
        <TourStep
          title={"Add Project"}
          description={"Enter the project code shared by your professor."}
        />
      ),
    },
    {
      target: "#assignment-unlock",
      content: (
        <TourStep
          title={"Access case study"}
          description={
            "Click to do the payment if needed, and access case study file."
          }
        />
      ),
    },
    {
      target: "#assignment-ind-download-case-study",
      content: (
        <TourStep
          title={"Download Case Study"}
          description={"Download PdF version of case study."}
        />
      ),
    },
    {
      target: "#assignment-ind-download-guideline",
      content: (
        <TourStep
          title={"Download Guideline"}
          description={"Download PdF version of guideline."}
        />
      ),
    },
    {
      target: "#assignment-group-download-case-study",
      content: (
        <TourStep
          title={"Download Case Study"}
          description={"Download PdF version of case study."}
        />
      ),
    },
    {
      target: "#assignment-group-download-guideline",
      content: (
        <TourStep
          title={"Download Guideline"}
          description={"Download PdF version of guideline."}
        />
      ),
    },
    {
      target: "#assignment-ind-answer-btn",
      content: (
        <TourStep
          title={"Submit/View Answer"}
          description={
            "To submit/view your answer. Answer should be submitted before due date."
          }
        />
      ),
    },
    {
      target: "#assignment-group-btn",
      content: (
        <TourStep
          title={"List of Groups"}
          description={
            "To create a group and submit answer. Answer should be submitted before due date."
          }
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <TourRide name="assignment-list-page" steps={tourSteps} />
      <PageHeader title="List of Projects" onRefresh={() => onRefresh()} />
      <SearchBar onSelect={handleSelection} sx={{ mb: 3, width: "80%" }} />
      <Grid container spacing={0}>
        {loading
          ? Array.from(new Array(3)).map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{ m: 2 }}>
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : assignments.map((assignment, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                {assignment.type === "INDIVIDUAL" ? (
                  <IndividualAssignmentCard
                    assignment={assignment}
                    handleDelete={() => handleDelete(assignment.id)}
                    handleDownload={(assignmentTitle) =>
                      downloadPdf(assignment, assignmentTitle)
                    }
                    handleGuideline={() =>
                      downloadGuideline(
                        assignment.caseStudyId,
                        assignment.id,
                        assignment.title
                      )
                    }
                  />
                ) : (
                  <AssignmentCard
                    assignment={assignment}
                    handleDelete={() => handleDelete(assignment.id)}
                    handleGroupList={() =>
                      handleGroupList(assignment, assignment.caseStudyId)
                    }
                    handleDownload={(assignmentTitle) =>
                      downloadPdf(assignment, assignmentTitle)
                    }
                    handleGuideline={() =>
                      downloadGuideline(
                        assignment.caseStudyId,
                        assignment.id,
                        assignment.title
                      )
                    }
                  />
                )}
              </Grid>
            ))}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ResponsiveDialog
        dialog={disclaimerDialog}
        setDialog={setDisclaimerDialog}
      />
    </Container>
  );
}
