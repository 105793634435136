import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
  CardContent,
  Collapse,
} from "@mui/material";
import { AppContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import * as assignmentService from "../../services/AssignmentService";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/mui/Notification";
import PageHeader from "../../components/PageHeader";
import { MuiInput, TourRide, TourStep } from "../../components";
import { useForm, Form } from "../../components/mui/useForm";
import { LoadingButton } from "@mui/lab";
import { isEpmty } from "../../helper/TextHelper";
import GroupCard from "./components/GroupCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { CASE_STUDY_ASSIGNMENT_ANSWER } from "../../helper/Constants";

export default function Groups() {
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const currentUserId = appState.userInfo?.userId;
  const { state } = useLocation();
  const [expanded, setExpanded] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [groups, setGroups] = useState([]);
  const assignment = state.assignment;
  const assignmentId = state.assignment?.id;
  const caseStudyId = state.caseStudyId;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    assignmentService
      .getAllGroups(assignmentId)
      .then((response) => {
        const reorderedList = response.data.sort((a, b) => {
          const aHasTargetUser = a.members.some(
            (member) => member.userId === currentUserId
          );
          const bHasTargetUser = b.members.some(
            (member) => member.userId === currentUserId
          );

          if (aHasTargetUser && !bHasTargetUser) {
            return -1; // a comes first
          } else if (!aHasTargetUser && bHasTargetUser) {
            return 1; // b comes first
          } else {
            return 0; // keep original order
          }
        });
        setGroups(reorderedList);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const onJoin = (groupId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .join(assignmentId, groupId)
      .then((response) => {
        const group = groups.find((item) => item.id === groupId);

        if (group) {
          // Add user to the member array
          group.members.push(response.data);
        }
        setNotify({
          isOpen: true,
          message: "Successfully joined to the Group.",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (e.response.status === 409) {
            setNotify({
              isOpen: true,
              message: "You are not allowed to join in more than one group.",
              type: "warning",
            });
          } else {
            setNotify({
              isOpen: true,
              message: "Something went wrong. Please try again later.",
              type: "error",
            });
          }
        } else {
          setNotify({
            isOpen: true,
            message: "Could not add you to the group. Please try again later.",
            type: "error",
          });
        }
      });
  };

  const handleJoin = (groupId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Would you like to proceed with joining this group?",
      subTitle: "Joining will give you access to submit.",
      onConfirm: () => {
        onJoin(groupId);
      },
    });
  };

  const onDelete = (groupId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .deleteGroup(groupId)
      .then((response) => {
        const newList = groups.filter((item) => item.id !== groupId);
        setGroups(newList);
        setNotify({
          isOpen: true,
          message: "Successfully deleted a Group",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (e.response.status === 406) {
            setNotify({
              isOpen: true,
              message: "Group has members. Cannot be deleted.",
              type: "error",
            });
            onRefresh();
          } else {
            setNotify({
              isOpen: true,
              message: "Something went wrong. Please try again later.",
              type: "error",
            });
          }
        } else {
          setNotify({
            isOpen: true,
            message: "Could not delete a group. Please try again later.",
            type: "error",
          });
        }
      });
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this group?",
      subTitle: "You will be able to create a new group if needed.",
      onConfirm: () => {
        onDelete(id);
      },
    });
  };

  const onLeave = (groupId, userId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .leave(groupId)
      .then((response) => {
        const group = groups.find((item) => item.id === groupId);

        if (group) {
          // Find the index of the member by id
          const memberIndex = group.members.findIndex(
            (member) => member.userId === userId
          );
          if (memberIndex !== -1) {
            // Remove the member from the group's members list
            group.members.splice(memberIndex, 1);
          }
        }
        setNotify({
          isOpen: true,
          message: "Successfully left the Group",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message:
            "Could not leave you from the group. Please try again later.",
          type: "error",
        });
      });
  };

  const handleLeave = (groupId, userId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Would you like to proceed with leaving this group?",
      subTitle: "All your access will be gone.",
      onConfirm: () => {
        onLeave(groupId, userId);
      },
    });
  };

  const handleAnswerClick = (group) => {
    navigate(CASE_STUDY_ASSIGNMENT_ANSWER, {
      state: { assignment, group, caseStudyId },
    });
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = isEpmty(fieldValues.name) ? "This field is required." : "";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    { name: "" },
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setSubmitting(true);
      assignmentService
        .create({ name: values.name, assignmentId: assignmentId })
        .then((response) => {
          resetForm();
          setGroups((prevGroups) => [{ ...response.data }, ...prevGroups]);
          setExpanded(false);
        })
        .catch((error) => {
          console.error(`Error: ${error.message}`);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 409) {
              setNotify({
                isOpen: true,
                message: "Group name already exists.",
                type: "error",
              });
            } else if (error.response.status === 406) {
              setNotify({
                isOpen: true,
                message: "You are not allowed to create more than one group.",
                type: "error",
              });
            } else {
              setNotify({
                isOpen: true,
                message: "Could not add a group. Please try again later.",
                type: "error",
              });
            }
          } else {
            setNotify({
              isOpen: true,
              message: "Something went wrong. Please try again later.",
              type: "error",
            });
          }
        })
        .finally(() => setSubmitting(false));
    }
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const tourSteps = [
    {
      target: "#assignment-group-create",
      content: (
        <TourStep
          title={"Create New Group"}
          description={
            "Create a new group, choose a name, and invite your friends. If your teammate has already created a group, please do not create a duplicate."
          }
        />
      ),
    },
    {
      target: "#assignment-group-join-btn",
      content: (
        <TourStep
          title={"Join Group"}
          description={
            "If you find your group and teammates, you can join the group."
          }
        />
      ),
    },
    {
      target: "#assignment-group-leave-btn",
      content: (
        <TourStep
          title={"Leave Group"}
          description={"You can leave the group by click on this button."}
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <TourRide name="assignment-groups-page" steps={tourSteps} />
      <PageHeader
        title="List of Groups"
        onRefresh={() => onRefresh()}
        isChild={true}
      />
      <Card elevation={3} sx={{ m: 0.5 }}>
        <CardHeader
          sx={{ m: 1 }}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
          title={
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="h6"
              gutterBottom
              onClick={handleExpandClick}
            >
              Create New Group
            </Typography>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ m: 0 }}>
            <Form onSubmit={handleSubmit}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <MuiInput
                  id="assignment-group-create"
                  name="name"
                  label="Group Name"
                  value={values.name}
                  onChange={(e) => handleInputChange(e)}
                  helperText="Team A,B,... / Team 1,2,..."
                  error={errors?.name}
                />
              </Stack>
              <LoadingButton
                sx={{ mt: 1, ml: 1 }}
                type="submit"
                color="success"
                variant="outlined"
                loading={submitting}
              >
                Submit
              </LoadingButton>
            </Form>
          </CardContent>
        </Collapse>
      </Card>
      <Grid container spacing={0}>
        {loading
          ? Array.from(new Array(3)).map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{ m: 2 }}>
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : groups.map((group, index) => (
              // <Grid key={index} item xs={12}>
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <GroupCard
                  assignment={assignment}
                  group={group}
                  handleJoin={(groupId) => handleJoin(groupId)}
                  handleDelete={() => handleDelete(group.id)}
                  handleLeave={(groupId, userId) =>
                    handleLeave(groupId, userId)
                  }
                  handleAnswerClick={() => handleAnswerClick(group)}
                />
              </Grid>
            ))}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
