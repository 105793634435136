import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const ResponsiveDialog = ({ dialog, setDialog, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialog.isOpen}
      onClose={() => setDialog({ ...dialog, isOpen: false })}
      aria-labelledby="responsive-dialog-compoenent-title"
      {...props}
    >
      {dialog.title && (
        <DialogTitle id="responsive-dialog-compoenent-title">
          {dialog.title}
        </DialogTitle>
      )}
      {dialog.content && (
        <DialogContent>
          {typeof dialog.content === "string" ? (
            <DialogContentText>{dialog.content}</DialogContentText>
          ) : (
            dialog.content
          )}
        </DialogContent>
      )}
      <DialogActions>
        {dialog.actions?.map((action, index) => (
          <Button
            key={index}
            onClick={action.onClick}
            autoFocus={action.autoFocus}
          >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ResponsiveDialog;
