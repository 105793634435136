import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { MuiInput, MuiButton } from "../../components";
import { Form } from "../../components/mui/useForm";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../components/PageHeader";
import * as assignmentService from "../../services/AssignmentService";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Submitted } from "./Submitted";
import LoadingSpinner from "../../components/LoadingSpinner";
import { isEpmty } from "../../helper/TextHelper";
import { AppContext } from "../../App";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  PROFILE_ROUTE,
  STUDENT_ASSIGNMENTS_ROUTE,
} from "../../helper/Constants";
import { red } from "@mui/material/colors";
import { isCurrentDateBefore } from "../../helper/DateHelper";

export default function AnswerForm(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { appState } = useContext(AppContext);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [requestingFeedback, setRequestingFeedback] = useState({});
  const [feedbackRequested, setFeedbackRequested] = useState({});
  const [feedbacksLoading, setFeedbacksLoading] = useState();
  const [feedbacks, setFeedbacks] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState();
  const [submitted, setSubmitted] = useState();
  const [questions, setQuestions] = useState([]);
  const [questionError, setQuestionError] = useState({});
  const caseStudyId = state.caseStudyId;
  const assignmentId = state.assignmentId;
  const studentLevel = state.studentLevel;
  const groupId = state.groupId;

  useEffect(() => {
    // when individual submit an answer, automatically a group will be created in the backend
    if (groupId) {
      assignmentService
        .getAllQuestionAnswers(groupId, assignmentId)
        .then((response) => {
          setQuestions(response.data);
          response.data.forEach((element) => {
            if (isFeedbackProvided(element)) {
              getFeedbacks(element.feedbackReqId);
            }
          });
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setLoading(false));
    } else {
      assignmentService
        .getAllQuestions(caseStudyId)
        .then((response) => {
          setQuestions(response.data);
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFeedbacks = (feedbackReqId) => {
    setFeedbacksLoading(true);
    assignmentService
      .getFeedbacks(feedbackReqId)
      .then((response) => {
        setFeedbacks((prevData) => ({
          ...prevData,
          [feedbackReqId]: response.data,
        }));
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setFeedbacksLoading(false));
  };

  const handleFeedbackRequest = (questionId, evaluationId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure you want to request a feeback?",
      subTitle: "",
      onConfirm: () => {
        sendFeedbackRequest(questionId, evaluationId);
      },
    });
  };

  const sendFeedbackRequest = (questionId, evaluationId) => {
    setRequestingFeedback((prev) => ({ ...prev, [questionId]: true }));
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .requestFeedback({
        evaluationId: evaluationId,
        groupId: groupId,
        questionId: questionId,
        caseStudyId: caseStudyId,
        assignmentId: assignmentId,
      })
      .then((response) => {
        setFeedbackRequested((prev) => ({ ...prev, [questionId]: true }));
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not submit a request. Please try again later.",
          type: "error",
        });
      })
      .finally(() =>
        setRequestingFeedback((prev) => ({ ...prev, [questionId]: false }))
      );
  };

  const validate = () => {
    const newQuestionErrors = {};

    questions.forEach((question) => {
      if (isEpmty(question.answer))
        newQuestionErrors[question.questionId] = `This field is required.`;
    });

    // Update the error state with any found errors
    setQuestionError(newQuestionErrors);

    // Return true if no errors, otherwise false
    return Object.keys(newQuestionErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure you want to submit your case study?",
        subTitle:
          "Once submitted, you will not be able to make any changes. Please confirm your name.",
        addons: (
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <React.Fragment key={"member.id"}>
              <ListItem
                alignItems="center"
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    color="primary"
                    onClick={() =>
                      navigate(PROFILE_ROUTE, {
                        state: {
                          redirect: STUDENT_ASSIGNMENTS_ROUTE,
                        },
                      })
                    }
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar alt={appState.userInfo?.firstName} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    appState.userInfo?.firstName +
                    " " +
                    appState.userInfo?.lastName
                  }
                  sx={{ display: "flex", alignItems: "center" }} // Ensure text is centered vertically
                />
              </ListItem>
            </React.Fragment>
          </List>
        ),
        onConfirm: () => {
          sendAnswer();
        },
      });
    }
  };

  const sendAnswer = () => {
    setSubmitting(true);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .submitIndividualAnswer({
        questions: questions,
        caseStudyId: caseStudyId,
        assignmentId: assignmentId,
        studentLevel: studentLevel,
      })
      .then((response) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not submit an answer. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setSubmitting(false));
  };

  const inputChangeHandler = (questionId, e) => {
    const { value } = e.target;

    const updatedQuestions = questions.map((q) => {
      if (q.questionId === questionId) {
        return {
          ...q,
          answer: value,
        };
      }
      return q;
    });

    setQuestions(updatedQuestions);
  };

  const cancelBackBtnText = () => {
    return groupId ? "Back" : "Cancel";
  };

  const title = () => {
    return groupId ? "Submitted Answer" : "Submit Answer";
  };

  const feedbackBtnShouldBeDiabled = (question) => {
    if (
      !isCurrentDateBefore(question.feedbackDueDate) ||
      hasRequestedForFeedback(question)
    ) {
      return true;
    }
    return false;
  };

  const hasRequestedForFeedback = (question) =>
    !!question.feedbackReqId || feedbackRequested[question.questionId];

  const isFeedbackProvided = (question) =>
    "CONFIRMED" === question.feedbackReqStatus;

  const showFeedbackTitle = (question) => {
    if (!isCurrentDateBefore(question.feedbackDueDate)) {
      return "Feedback deadline has expired";
    } else if (hasRequestedForFeedback(question)) {
      return "Waiting for feedback";
    } else {
      return "Request a feedback";
    }
  };

  const isAnswerSubmitted = !!groupId;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title={title()} isChild={true} />
      {!submitted ? (
        loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {!isAnswerSubmitted && (
              <Alert variant="filled" severity="warning">
                <AlertTitle>Important</AlertTitle>
                Please draft your answer in Google Docs or Microsoft Word first,
                then paste the final one here. The textbox below cannot save
                your draft.
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Paper
                variant="outlined"
                sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}
              >
                {questions.map((question, qIndex) => (
                  <Card
                    key={qIndex}
                    sx={{
                      m: 2,
                    }}
                  >
                    {question.grade && (
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ bgcolor: red[300] }}
                            aria-label="recipe"
                          >
                            {question.grade}
                          </Avatar>
                        }
                        title={<Typography variant="h6">Your Grade</Typography>}
                        subheader={
                          question.grade && (
                            <>
                              <Typography variant="h6" sx={{ ml: 0 }}>
                                {question.grade + "/100"}
                              </Typography>
                              {question.feedbackThreshold &&
                                question.grade < question.feedbackThreshold &&
                                !isFeedbackProvided(question) && (
                                  <Box display="flex" alignItems="center">
                                    <LoadingButton
                                      onClick={() =>
                                        handleFeedbackRequest(
                                          question.questionId,
                                          question.evaluationId
                                        )
                                      }
                                      color="info"
                                      loading={
                                        requestingFeedback[
                                          question.questionId
                                        ] || false
                                      }
                                      size="small"
                                      disabled={feedbackBtnShouldBeDiabled(
                                        question
                                      )}
                                    >
                                      {showFeedbackTitle(question)}
                                    </LoadingButton>
                                  </Box>
                                )}
                            </>
                          )
                        }
                      />
                    )}
                    <CardContent>
                      <Typography variant="h6">{"Question"}</Typography>
                      <Typography sx={{ ml: 2, mb: 2 }}>
                        {question.title}
                      </Typography>
                      {isAnswerSubmitted ? (
                        <>
                          <Typography variant="h6">{"Answer"}</Typography>
                          <Typography sx={{ ml: 2, mb: 2 }}>
                            {question.answer}
                          </Typography>
                        </>
                      ) : (
                        <Grid container>
                          <Grid item xs={12} sx={{ mt: 0 }} align="center">
                            <MuiInput
                              label={"Enter your answer"}
                              name="content"
                              multiline
                              minRows={25}
                              value={question.answer}
                              onChange={(e) =>
                                inputChangeHandler(question.questionId, e)
                              }
                              error={questionError[question.questionId]}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      )}
                      {feedbacks[question.feedbackReqId] && (
                        <>
                          <Typography
                            variant="h6"
                            sx={{ mt: 4 }}
                            key={question.feedbackReqId}
                          >
                            {"Feedbacks"}
                          </Typography>
                          {feedbacksLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <List
                              sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                              }}
                            >
                              {feedbacks[question.feedbackReqId].map(
                                (feedback, fIndex) => (
                                  <>
                                    <ListItem
                                      key={fIndex}
                                      alignItems="flex-start"
                                    >
                                      <ListItemText
                                        primary={
                                          <React.Fragment>
                                            <Typography variant="body1">
                                              {feedback.title}
                                            </Typography>
                                          </React.Fragment>
                                        }
                                        secondary={
                                          <React.Fragment>
                                            <Typography variant="body2">
                                              {feedback.description}
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      />
                                    </ListItem>
                                  </>
                                )
                              )}
                            </List>
                          )}
                        </>
                      )}
                    </CardContent>
                  </Card>
                ))}
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction={{ xs: "column", sm: "row" }}
                  sx={{ ml: 1 }}
                >
                  {!isAnswerSubmitted && (
                    <LoadingButton
                      type="submit"
                      color="success"
                      variant="outlined"
                      loading={submitting}
                    >
                      Submit
                    </LoadingButton>
                  )}

                  <MuiButton
                    text={cancelBackBtnText()}
                    color="info"
                    variant="outlined"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Stack>
              </Paper>
              <Notification notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
            </Form>
          </>
        )
      ) : (
        <Submitted />
      )}
    </Container>
  );
}
